const data = [
    {
        text: 'Lonely Robot [2023]',
        img: require('../assets/gallery/Lonely Robot.jpg'),
    },
    {
        text: 'Porsche 911 [2018]',
        img: require('../assets/gallery/Porsche 911.jpg'),
    }
]

export default data;